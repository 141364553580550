/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Red+Hat+Display:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}

/* Global resets */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--ff-primary);
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
h1,
h2,
h3,
h4 {
  font-family: var(--font-main);
  font-size: var(--fs-400);
  font-weight: 700;
}

p {
  font-family: var(--font-main);
  font-size: var(--fs-200);
}

.none {
  display: none !important;
  height: 0 !important;
}

a.solid {
  color: var(--clr-light-100);
  background: var(--clr-primary-400);
  padding: 0.5rem 1.75rem;
  border-radius: 0.625rem;
  font-weight: 600;
  font-family: var(--font-main);
  text-decoration: none;
  font-size: var(--fs-200);
}
a.solid:hover, a.solid:focus {
  background: var(--clr-primary-300);
}

.divider {
  width: 100vw;
}

.max-width {
  width: 100%;
  max-width: var(--max-width);
  margin: auto;
  padding-inline: 10vw;
}

.section-title,
.section-description {
  text-align: center;
}

.section-description {
  font-family: var(--font-accent);
}

.icon {
  padding: 0.5rem;
}

.icon:hover,
.icon:focus {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.9) rotate(360deg);
  }
}
@keyframes up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes up-vh {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1.2, 1.2, 1);
  }
  40% {
    transform: translate3d(0, -30%, 0) scale3d(0.8, 1.4, 1);
  }
  100% {
    transform: translate3d(0, -100%, 0) scale3d(1.4, 0.8, 1);
  }
}
#root {
  flex: 1;
}

.App {
  min-height: 100vh;
}

.NavBar :root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}
.NavBar {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--clr-light-100);
  z-index: 2;
}
.NavBar .skip-links {
  position: absolute;
  text-decoration: none;
  color: var(--clr-primary-400);
  padding: 1rem;
  border: 2px solid var(--clr-primary-400);
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  transform: translateY(-150%);
  transition: transform 200ms ease-in;
}
.NavBar .skip-links:focus {
  transform: translateY(0);
}
.NavBar .hamburger-button {
  cursor: pointer;
  z-index: 2;
  background: none;
  border: none;
}
.NavBar .hamburger-button .line {
  width: 2rem;
  height: 3px;
  margin: 5px;
  background: var(--clr-primary-400);
  transition: background 500ms ease;
}
.NavBar .hamburger-button.open .line {
  background: var(--clr-light-100);
}
@media only screen and (min-width: 50rem) {
  .NavBar .hamburger-button {
    display: none;
  }
}
.NavBar nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 1rem;
}
.NavBar nav .nav-logo {
  width: 11rem;
}
@media only screen and (min-width: 50rem) {
  .NavBar nav {
    padding-block: 1.5rem;
  }
}
.NavBar nav .nav-links {
  display: flex;
  list-style: none;
  clip-path: circle(0% at 90% -10%);
  -webkit-clip-path: circle(0% at 90% -10%);
  position: fixed;
  background: var(--clr-primary-300);
  inset: 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40vh 0;
  transition: clip-path 1s ease;
  pointer-events: none;
  z-index: 1;
}
.NavBar nav .nav-links .nav-link {
  margin-left: 2rem;
  opacity: 0;
}
.NavBar nav .nav-links .nav-link:nth-child(1) {
  transition: opacity 500ms ease 150ms;
}
.NavBar nav .nav-links .nav-link:nth-child(2) {
  transition: opacity 500ms ease 300ms;
}
.NavBar nav .nav-links .nav-link:nth-child(3) {
  transition: opacity 500ms ease 450ms;
}
.NavBar nav .nav-links .nav-link:nth-child(4) {
  transition: opacity 500ms ease 600ms;
}
.NavBar nav .nav-links .nav-link a:not(.solid) {
  font-family: var(--font-main);
  text-decoration: none;
  color: var(--clr-light-100);
  padding: 0 0.2rem;
  font-size: var(--fs-200);
}
.NavBar nav .nav-links .nav-link a:not(.solid):hover, .NavBar nav .nav-links .nav-link a:not(.solid):focus {
  border-bottom: 1px solid var(--clr-primary-400);
}
.NavBar nav .nav-links.open {
  clip-path: circle(160% at 90% -10%);
  -webkit-clip-path: circle(160% at 90% -10%);
  pointer-events: all;
}
.NavBar nav .nav-links.open li {
  opacity: 1;
}
@media only screen and (min-width: 50rem) {
  .NavBar nav .nav-links {
    clip-path: initial;
    -webkit-clip-path: initial;
    position: initial;
    background: initial;
    inset: initial;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: initial;
    transition: clip-path 1s ease;
    pointer-events: initial;
    z-index: initial;
  }
  .NavBar nav .nav-links .nav-link {
    opacity: 1;
  }
  .NavBar nav .nav-links .nav-link a:not(.solid) {
    color: var(--clr-primary-400);
  }
}
@media only screen and (min-width: 65rem) {
  .NavBar nav .nav-links .nav-link {
    margin-left: 3rem;
  }
}

.Hero :root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}
.Hero {
  color: var(--clr-primary-400);
}
.Hero .hero-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding-block: 10vh;
}
@media only screen and (min-width: 50rem) {
  .Hero .hero-content {
    gap: min(6rem, 10vw);
    flex-direction: row;
  }
}
.Hero .hero-content .hero-image {
  min-width: 0;
}
.Hero .hero-content article {
  max-width: 24rem;
}
.Hero .hero-content article p {
  margin-bottom: 1rem;
}
.Hero .hero-content article p.hero-description {
  font-weight: 500;
}
.Hero .hero-content article p.hero-date {
  font-family: var(--font-accent);
}

.About :root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}
.About {
  background: var(--clr-primary-300);
  color: var(--clr-light-100);
}
.About .about-description {
  display: flex;
  gap: 2rem;
  padding-block: 5rem;
  align-items: center;
}
.About .about-description .about-text {
  font-weight: 300;
  margin-top: 1rem;
}
.About .sticky-notes {
  grid-template-columns: repeat(8, 1fr);
  row-gap: 0.5rem;
  display: none;
  width: 100%;
}
@media only screen and (min-width: 50rem) {
  .About .sticky-notes {
    display: grid;
  }
}
.About .sticky-notes .sticky-note:nth-child(1) {
  grid-column: 4/9;
}
.About .sticky-notes .sticky-note:nth-child(2) {
  grid-column: 1/7;
}
.About .sticky-notes .sticky-note:nth-child(3) {
  grid-column: 3/8;
}
.About .about-steps {
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 2rem 5rem;
}
.About .about-steps h2 {
  text-align: center;
}
.About .about-steps .step {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
  flex-direction: column;
}
@media only screen and (min-width: 35rem) {
  .About .about-steps .step {
    flex-direction: row;
  }
  .About .about-steps .step:nth-child(even) {
    flex-direction: row-reverse;
  }
}
.About .about-steps .step img {
  flex: 1;
}
.About .about-steps .step article {
  flex: 3;
}
.About .about-steps .step article .step-name {
  font-size: var(--fs-300);
}
.About .about-steps .step article .step-description {
  font-family: var(--font-accent);
}

.Supporters {
  background: var(--clr-primary-400);
  color: var(--clr-light-100);
}
.Supporters article {
  padding-block: 4rem;
}
.Supporters .tier-list {
  list-style: none;
  margin-block: 2rem;
}
.Supporters .tier-list .tier {
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  margin-bottom: 1rem;
}
.Supporters .tier-list .tier .tier-title {
  text-transform: capitalize;
  font-size: var(--fs-300);
  position: relative;
  margin: auto;
  background: var(--clr-primary-400);
  padding: 1rem;
  width: fit-content;
}
.Supporters .tier-list .tier .tier-title::before {
  content: "";
  border-bottom: solid 2px var(--clr-light-100);
  width: 100vw;
  left: -50vw;
  top: 50%;
  position: absolute;
  z-index: -1;
}
.Supporters .supporter-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin: auto;
  align-items: center;
  list-style: none;
}
.Supporters .supporter-list img {
  padding: 0.75rem;
  border-radius: 1rem;
  background: var(--clr-light-100);
  display: block;
  max-height: 10rem;
  max-width: 20rem;
  margin: auto;
  height: height;
  width: auto;
}
.Supporters .supporter-list.platinum .supporter {
  width: calc(100% - 2rem);
}
.Supporters .supporter-list.gold .supporter {
  width: calc(50% - 2rem);
  min-width: 20rem;
}
.Supporters .supporter-list.silver .supporter {
  width: calc(33.3% - 2rem);
  min-width: 15rem;
}
.Supporters .supporter-list.bronze .supporter, .Supporters .supporter-list.partner .supporter {
  width: calc(25% - 2rem);
  min-width: 10rem;
}
.Supporters .description {
  text-align: center;
  font-family: var(--font-accent);
}
.Supporters .description a {
  color: inherit;
}
.Supporters .description a:hover, .Supporters .description a:focus {
  color: var(--clr-primary-100);
}

.NonProfits :root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}
.NonProfits {
  background: var(--clr-light-100);
  color: var(--clr-primary-400);
}
.NonProfits article {
  padding-block: 4rem;
}
.NonProfits .section-title,
.NonProfits .section-description {
  text-align: center;
}
.NonProfits p {
  font-family: var(--font-accent);
}
.NonProfits .nonprofit-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
  list-style: none;
}
.NonProfits .nonprofit-card {
  width: calc(33.3% - 2rem);
  min-width: 15rem;
}
.NonProfits .nonprofit-card img {
  width: auto;
  height: 12rem;
  margin: 2rem auto;
}
.NonProfits .nonprofit-card h4 {
  text-align: center;
  font-size: var(--fs-300);
  line-height: 1;
  margin-bottom: 1rem;
}

.Team {
  background: var(--clr-primary-300);
  color: var(--clr-light-100);
  text-align: center;
}
.Team article {
  padding-top: 5rem;
  padding-bottom: 3rem;
}
.Team ul {
  list-style: none;
}
.Team .team-rank {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-block: 2rem;
  justify-content: center;
}
.Team .team-member .member-image {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 1rem;
}
.Team .team-member .pink-blob {
  width: 10rem;
  height: 10rem;
  background-color: var(--clr-primary-100);
  border-radius: 50%;
  margin: auto;
  margin-bottom: 1rem;
}
.Team .team-member .member-name {
  font-weight: 600;
  line-height: 1;
  font-size: var(--fs-250);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Team .team-member .pronoun-separator {
  margin-inline: 0.5rem;
}
.Team .team-member .member-pronouns {
  font-weight: 300;
  font-size: var(--fs-100);
  vertical-align: middle;
}
.Team .team-member .member-title {
  font-family: var(--font-accent);
  font-size: var(--fs-100);
}
.Team .team-member .contact-links {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Register :root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}
.Register {
  background: var(--clr-primary-400);
  color: var(--clr-light-100);
}
.Register article {
  padding-block: 3rem;
}
.Register .eventbrite {
  margin-block: 2rem;
}
.Register a {
  color: var(--clr-light-100);
}

.Footer :root {
  --clr-light-100: #ffffff;
  --clr-primary-100: #c996cc;
  --clr-primary-200: #916bbf;
  --clr-primary-300: #3d2c8d;
  --clr-primary-400: #1c0c5b;
  --clr-text-100: #383838;
  --clr-text-200: #000000;
  --font-main: "Red Hat Display", sans-serif;
  --font-accent: "Anonymous Pro", monospace;
  --fs-100: 0.875rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.5rem;
  --fs-400: 2.25rem;
  --max-width: 100rem;
}
.Footer {
  background: var(--clr-primary-400);
  color: var(--clr-light-100);
}
.Footer .footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}
.Footer .footer-container .contact {
  text-align: center;
  margin-right: 2rem;
}
.Footer .footer-container .contact .solid {
  display: block;
  margin-top: 0.5rem;
  background: var(--clr-primary-300);
  width: max-content;
}
.Footer .footer-container .contact .solid:focus, .Footer .footer-container .contact .solid:hover {
  background: var(--clr-primary-200);
}
.Footer .footer-container .contact-info {
  margin-top: 0.5rem;
  margin-left: -0.5rem;
  align-items: center;
  display: flex;
}
@media only screen and (min-width: 50rem) {
  .Footer .footer-container {
    flex-direction: row;
  }
  .Footer .footer-container .adas-team {
    max-width: 30rem;
  }
}
.Footer footer {
  position: relative;
  padding-inline: 10vw;
}
@media only screen and (min-width: 50rem) {
  .Footer footer {
    padding-bottom: 2rem;
  }
  .Footer footer::after {
    content: url("../assets/ada_bot.png");
    position: absolute;
    right: 0;
    top: -50%;
  }
}

