@use "../base/mixins" as *;
@use "../base/variables" as *;

& {
	background: var(--clr-light-100);
	color: var(--clr-primary-400);
}

article {
	padding-block: 4rem;
}

.section-title,
.section-description {
	text-align: center;
}

p {
	font-family: var(--font-accent);
}

.nonprofit-list {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: space-around;
	list-style: none;
}

.nonprofit-card {
	width: calc(33.3% - 2rem);
	min-width: 15rem;

	img {
		width: auto;
		height: 12rem;
		margin: 2rem auto;
	}

	h4 {
		text-align: center;
		font-size: var(--fs-300);
		line-height: 1;
		margin-bottom: 1rem;
	}
}
