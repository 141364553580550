// Breakpoints
$brk-small: 35rem;
$brk-medium: 50rem;
$brk-large: 65rem;

:root {
	// Colors
	--clr-light-100: #ffffff;

	--clr-primary-100: #c996cc;
	--clr-primary-200: #916bbf;
	--clr-primary-300: #3d2c8d;
	--clr-primary-400: #1c0c5b;

	--clr-text-100: #383838;
	--clr-text-200: #000000;

	// Fonts
	--font-main: "Red Hat Display", sans-serif;
	--font-accent: "Anonymous Pro", monospace;

	// Font sizes
	--fs-100: 0.875rem;
	--fs-200: 1rem;
	--fs-250: 1.25rem;
	--fs-300: 1.5rem;
	--fs-400: 2.25rem;

	--max-width: 100rem;
}
