@use "../base/mixins" as *;
@use "../base/variables" as *;

& {
	color: var(--clr-primary-400);
}

.hero-content {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 2rem;
	padding-block: 10vh;

	@include mq($brk-medium) {
		gap: min(6rem, 10vw);
		flex-direction: row;
	}

	.hero-image {
		min-width: 0;
	}

	article {
		max-width: 24rem;

		p {
			margin-bottom: 1rem;

			&.hero-description {
				font-weight: 500;
			}

			&.hero-date {
				font-family: var(--font-accent);
			}
		}
	}
}
