@use "../base/mixins" as *;
@use "../base/variables" as *;

& {
	background: var(--clr-primary-400);
	color: var(--clr-light-100);
}

article {
	padding-block: 3rem;
}

.eventbrite {
	margin-block: 2rem;
}

a {
	color: var(--clr-light-100);
}
