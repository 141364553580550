& {
	background: var(--clr-primary-400);
	color: var(--clr-light-100);
}

article {
	padding-block: 4rem;
}

.tier-list {
	list-style: none;
	margin-block: 2rem;

	.tier {
		position: relative;
		z-index: 0;
		overflow-x: hidden;
		margin-bottom: 1rem;

		.tier-title {
			text-transform: capitalize;
			font-size: var(--fs-300);
			position: relative;
			margin: auto;
			background: var(--clr-primary-400);
			padding: 1rem;
			width: fit-content;

			&::before {
				content: "";
				border-bottom: solid 2px var(--clr-light-100);
				width: 100vw;
				left: -50vw;
				top: 50%;

				position: absolute;
				z-index: -1;
			}
		}
	}
}

.supporter-list {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: center;
	margin: auto;
	align-items: center;
	list-style: none;

	img {
		padding: 0.75rem;
		border-radius: 1rem;
		background: var(--clr-light-100);
		display: block;
		max-height: 10rem;
		max-width: 20rem;
		margin: auto;
		height: height;
		width: auto;
	}

	&.platinum .supporter {
		width: calc(100% - 2rem);
	}

	&.gold .supporter {
		width: calc(50% - 2rem);
		min-width: 20rem;
	}

	&.silver .supporter {
		width: calc(33.3% - 2rem);
		min-width: 15rem;
	}

	&.bronze .supporter,
	&.partner .supporter {
		width: calc(25% - 2rem);
		min-width: 10rem;
	}
}

.description {
	text-align: center;
	font-family: var(--font-accent);

	a {
		color: inherit;

		&:hover,
		&:focus {
			color: var(--clr-primary-100);
		}
	}
}
